body {
  margin: 0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: white;
  background: #000000;
  scroll-behavior: smooth;
}
.background {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}
.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.star {
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  animation: twinkle 2s infinite ease-in-out;
}
@keyframes twinkle {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
}
.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 2em;
  padding-top: 1em;
}
.parent-company {
  text-align: center;
  padding: 2em;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  margin: 0.5em auto 1em auto;
  width: 80%;
  max-width: 800px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.logo {
  width: 100px;
  margin-bottom: 20px;
  background-image: linear-gradient(to right, #e5e5e5, #87ceeb);
  border-radius: 20px;
  padding: 80px;
  opacity: 0.3;
  box-shadow: 15px 15px 20px #77a9b5;
}
.rwe-logo {
  width: 400px;
  margin: 0 auto;
  margin-bottom: 20px;
  /* background-image: linear-gradient(to right, #e5e5e5, #87ceeb); */
  background-color: white;
  border-radius: 20px;
  /* opacity: 0.35; */
  box-shadow: 15px 15px 20px #77a9b5;
}
.wr-logo {
  width: 450px;
  /* background-image: linear-gradient(to right, #e5e5e5, #a4f4a4); */
  border-radius: 20px;
  margin-bottom: -3%;
}
.ata-logo {
  width: 425px;
  border-radius: 20px;
  margin-bottom: -3%;
}
.tirc-logo {
  height: 295px;
  /* background-image: linear-gradient(to right, #e5e5e5, #a4f4a4); */
  border-radius: 20px;
  margin-bottom: -3%;
}
.slogan {
  font-style: italic;
  margin-bottom: 10px;
}
hr {
  border: 1px solid #ffffff;
  margin: 20px auto;
  width: 50%;
}
.description {
  margin-bottom: 50px;
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  padding: 20px;
}
.list {
  list-style-type: none;
  padding: 0;
}
.list li {
  margin: 10px 0;
}
.children-companies {
  /* display: flex; */
  justify-content: space-around;
  padding: 2em;
  position: relative;
}
.child-company {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  /* width: 30%; */
  text-align: center;
  padding: 1em;
  margin-bottom: 20px;
}
.child-company a {
  text-decoration: none;
  color: white;
}
.child-logo {
  width: 80px;
  margin-bottom: 15px;
}
/* .connectors {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 50px;
}
.vertical-connector {
  position: absolute;
  left: 50%;
  top: -25px;
  width: 5px;
  height: 75px;
  background-color: #6A8CC1;
  border-radius: 20%;
  opacity: 0.3;
}
.horizontal-connector {
  position: absolute;
  top: 25px;
  left: 10%;
  width: 80%;
  height: 5px;
  background-color: #87ceeb;
  border-radius: 20%;
  opacity: 0.6;
  /* background-image: url("/public/bgsky.png");
  border-radius: 20%;
  z-index: 1;
}
.child-connector {
  position: absolute;
  top: 25px;
  width: 5px;
  height: 25px;
  background-color: #6A8CC1;
  border-radius: 20%;
  opacity: 0.3;
}
.child-connector:nth-child(3) { left: 16.67%; }
.child-connector:nth-child(4) { left: 50%; }
.child-connector:nth-child(5) { left: 83.33%; }

@media screen and (min-width: 1440px) {
  .connectors {
      display: block;
  }
} */

.footer-nav {
  display: flex;
  justify-content: center;
  padding: 1em;
  background: rgba(0, 0, 0, 0.7);
  margin-top: 250px;
}
.footer-nav a {
  margin: 0 10px;
  text-decoration: none;
  color: white;
}
.footer-nav a:hover {
  text-decoration: underline;
}
.terms h1, .terms h2 {
  color: #a69f9f;
  text-align: left;
}
.terms p {
  margin-bottom: 15px;
  text-align: left;
}
.aboutus p {
  text-align: left;
}
@media only screen and (max-width: 700px) {
  .rwe-logo {
      width: 280px;
  }
  .wr-logo {
    width: 350px;
  }
  .ata-logo {
    width: 325px;
  }
  .tirc-logo {
    height: 200px;
    width: 350px;
  }
} 
@media only screen and (max-width: 500px) {
  .rwe-logo {
    width: 200px;
  }
  .children-companies {
    flex-direction: column;
    align-items: center;
  }
  .wr-logo {
    width: 200px;
    background: white;
  }
  .ata-logo {
    width: 200px;
    background: #221328;
  }
  .tirc-logo {
    /* height: 165px; */
    width: 200px;
    background: #0b0a0a;
  }
} 
@media only screen and (max-width: 321px) {
  .wr-logo {
    width: 164px;
  }
  .ata-logo {
    width: 164px;
  }
  .tirc-logo {
    height: 135px;
    width: 164px;
  }
} 
